import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../prismic-configuration';

const Accent = ({ color }) => {

  return (
    <span className={`accent bg-${ color || `green` }`}></span>
  );
}

export default Accent
