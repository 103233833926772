import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../prismic-configuration';
import { Button } from '../components';
import { Disclosure } from '@headlessui/react'

import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

const Navbar = ({ links, cta, logo }) => {

  const navbar_navigation = links.map(function (slice, index) {
    if( slice.slice_type === 'link' ) {
      return (
        <a key={index} href={Link.url(slice.primary.link, linkResolver)} title={slice.primary.text}>{slice.primary.text}</a>
      );
    } else if ( slice.slice_type === 'dropdown' ) {

      var dropdown_links = slice.items.map(function(link, index) {
          return (
            <a href={Link.url( link.dropdown_item_link, linkResolver )} title={link.dropdown_item_text}>{link.dropdown_item_text}</a>
          );
      });

      return (
        <a href={Link.url(slice.primary.parent_link, linkResolver)} title={slice.primary.parent_text}>{slice.primary.parent_text}<img src="https://prismic-io.s3.amazonaws.com/integrated-labor/c8a22da9-3f8a-432d-b6bb-21a7d65d9b63_Caret.svg" alt="Caret" />
          <nav className="dropdown">
            { dropdown_links }
          </nav>
        </a>
      );
    }
  });
  const mobile_navbar_navigation = links.map(function (slice, index) {
    if( slice.slice_type === 'link' ) {
      return (
        <a key={index} href={Link.url(slice.primary.link, linkResolver)}  className="font-bold" title={slice.primary.text}>{slice.primary.text}</a>
      );
    } else if ( slice.slice_type === 'dropdown' ) {

      var dropdown_links = slice.items.map(function(link, index) {
          return (
            <a href={Link.url( link.dropdown_item_link, linkResolver )}  className="font-normal" title={link.dropdown_item_text}>{link.dropdown_item_text}</a>
          );
      });

      return (
        <a href={Link.url(slice.primary.parent_link, linkResolver)} className="font-bold" title={slice.primary.parent_text}>{slice.primary.parent_text}<img src="https://prismic-io.s3.amazonaws.com/integrated-labor/c8a22da9-3f8a-432d-b6bb-21a7d65d9b63_Caret.svg" alt="Caret" />
          <nav className="dropdown-mobile">
            { dropdown_links }
          </nav>
        </a>
      );
    }
  });

  
  const Jump = () => {

    if( cta.show ) {
      return(
        <div className="jump">
          <Button
            url={Link.url(cta.link, linkResolver)}
            type='green'
            size='Medium'
            text={cta.text} />
        </div>
      );
    } else {
      return null;
    }

  }

  return (
    <header className="navbar">
      <div className="flex flex-col mt-3 justify-center md:flex-row md:justify-around items-center  md:mt-0">
        <a id="logo" className="logo  mx-auto md:mx-0  " href="/">
          <img src={ logo.url } alt={ logo.alt } />
        </a>
        <div className='hidden md:inline-flex md:mt-4 '>
          <nav id="main-nav" className="main-nav ">
            { navbar_navigation }
          </nav>
          <Jump />
        </div>
        <div className='fixed  mt-1  inset-0 w-fit h-fit  md:hidden'>
          <Disclosure >
          {({ open }) => (
          <>
            <Disclosure.Button className="ml-4 bg-white md:hidden items-center  rounded-md p-2 text-[#00c55e] hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              
              {open ? (
                <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
                
              ) : (
                <Bars3Icon className="block h-8 w-8" aria-hidden="true" />
                
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500">
            <div className='w-screen h-screen p-10 bg-white'>
              <div className='w-fit mx-auto'>
              <nav id="main-nav" className="main-nav flex flex-col ">
                { mobile_navbar_navigation }
              </nav>
              <Jump />
              </div>
            </div>
            
            </Disclosure.Panel>
          </>)}
          </Disclosure>
        </div>
      </div>
    </header>
  );
}

export default Navbar
