import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

const TwoColumn = ( { one, two } ) => {
  return (
    <div className="section two-column rich-text">
      <div className="container">
        <div className="two-column__column">{ RichText.render( one, linkResolver ) }</div>
        <div className="two-column__column">{ RichText.render( two, linkResolver ) }</div>
      </div>
    </div>
  );
}

export default TwoColumn
