import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../prismic-configuration';


const Footer = ({ social, location, contact, newsletter, founded }) => {

  const Social = () => {

    const FooterSocialLinks = social.map(function(link, index) {
      return (
        <a className="parent" title={link.platform} key={index} href={Link.url(link.link, linkResolver)}>{link.platform}</a>
      );
    });

    return FooterSocialLinks;

  }
  
  return (
    <footer id="page-footer" className="page-footer">
      <div className="container">
        <div className="footer-group">
          <header className="footer-group__header">Navigate</header>
          <section className="footer-group__cell">
            <nav className="footer-nav">
              <a className="parent" title="Platform" href="/platform">Platform</a>
              <a title="Features" href="/platform#core-features">Features</a>
              <a title="Use Cases" href="/platform#use-cases">Use Cases</a>
              <a title="Request Demo" href="/contact">Request Demo</a>
            </nav>
          </section>
          <section className="footer-group__cell">
            <nav className="footer-nav">
              <a className="parent" title="Services" href="/services">Services</a>
              <a title="Consulting" href="/consulting">Consulting</a>
              <a title="Data Migration" href="/data-migration">Data Migration</a>
              <a title="3P Data Exchange" href="/data-exchange">3P Data Exchange</a>
              <a title="Training & Support" href="/training-support">Training &amp; Support</a>
            </nav>
          </section>
          <section className="footer-group__cell">
            <nav className="footer-nav">
              <a className="parent" title="Resources" href="/insights">Resources</a>
              <a title="Insights" href="/insights">Insights</a>
            </nav>
          </section>
        </div>
        <div className="footer-group">
          <header className="footer-group__header">Contact Us</header>
          <section className="footer-group__cell">
            <p className="white">{ location.address1 }<br />{ location.address2 }<br />{ location.city }, { location.state } { location.zip }<br />United States</p>
            <p><a href={'tel:' + contact.phone.replace(/[^0-9]/g, '') } title="Call Us">{ contact.phone }</a><br />
            <a href={'mailto:' + contact.email} title="Email Us">{contact.email}</a></p>
          </section>
          {/* <section className="footer-group__cell">
            <p className="white">{newsletter}</p>
            <p><a href="#" className="btn btn--std btn--solid btn--green" title="Newsletter">Sign Up</a></p>
          </section> */}
          <section className="footer-group__cell">
            <nav className="footer-nav">
              <Social />
            </nav>
          </section>
          <section className="footer-group__cell"></section>
        </div>
        <div className="footer-group">
          <div className="copyright white w-full text-center md:text-left">&copy; {founded}-{(new Date().getFullYear())} Integrated Labor Solutions. All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
