import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../../prismic-configuration';

import { Button } from '../../../components';

const PostGrid = ({ posts, curr, next, prev }) => {

  var previousPage = parseInt(curr - 1),
      nextPage = parseInt(curr + 1);

  const Tags = ({ tags }) => {
    return tags.map( function( tag, index ) {
      return (
        <a key={index} href={`/insights?tag=${ tag.tag.toLowerCase().replace(' ', '-') }`} className="tag" title={tag.tag}>{tag.tag}</a>
      );
    });
  }

  const Post = ({ post }) => {
    return(
      <a className="post-block" href={`insights/${ post.uid }`} title={ post.data.title }>
        <div className="post-block__image">
          <img src={ post.data.image.thumb.url } alt={post.data.image.thumb.alt} />
        </div>
        <div className="post-block__cat">{ post.data.category.data.name }</div>
        <div className="post-block__title">{ post.data.title }</div>
        <div className="post-block__caption">{ post.data.caption }</div>
        <footer className="post-block__tags">
          <Tags tags={ post.data.tags } />
        </footer>
      </a>
    );
  }

  const ThePosts = () => {
    if( posts.length ) {
      return posts.map( function( postData, index ) {
        return ( <Post key={index}  post={postData} /> );
      });
    } else {
      return (
        <p className="post-grid__sorry" align="center">Sorry, we couldn't find any posts that match your query...</p>
      );
    }
  }

  return (
    <div className="section post-grid">
      <div className="container">
        <div className="post-grid__posts">
          <ThePosts />
        </div>
        <footer className="post-grid__footer">
          <div className="post-grid__prev">{ prev !== null
            ? <Button
                url={ `/insights/page/${ previousPage }` }
                type={`outline`}
                size='Medium'
                text='Previous Page'
              />
            : null }</div>
          <div className="post-grid__next">{ next !== null
            ? <Button
                url={ `/insights/page/${ nextPage }` }
                type={`outline`}
                size='Medium'
                text='Next Page'
              />
            : null }</div>
        </footer>
      </div>
    </div>
  );
}

export default PostGrid
