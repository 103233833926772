import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

const PageHero = ({ breadcrumb, headline, caption }) => {

  const Breadcrumb = () => {

    return breadcrumb.map(function (link, index) {
      return(
        <span className="breadcrumb__link" key={index} >
          <a href={Link.url(link.link, linkResolver)} title={link.text}>{link.text}</a>&nbsp;/&nbsp;
        </span>
      );
    });

  }

  return (
    <div className="page-hero">
      <div className="container">
        <div className="breadcrumb">
          <Breadcrumb /> { headline }
        </div>
        <h1>{ headline }</h1>
        <p className="page-hero__caption">{ caption }</p>
      </div>
    </div>
  );
}

export default PageHero
