import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import { client, linkResolver } from '../prismic-configuration';
import NotFound from './NotFound';
import { Helmet } from 'react-helmet';

// Components

  // Page Globals
  import    Navbar          from '../globals/Navbar';
  import    Footer          from '../globals/Footer';

  // SEO

  // Page Sections

    // Basic Sections
    import  {
      HomeHero,
      PageHero,
      TwoColumn,
      CallToAction,
      LatestPosts,
      UseCaseGrid,
      Clients,
      PlatformHero }           from '../sections/basic';

    // Customizable Sections
    import  Split           from '../sections/custom/Split';
    import  Image           from '../sections/custom/Image';
    import  FullWidthSplit  from '../sections/custom/FullWidthSplit';

    // Page-Specific Sections

      // Home Page
      import    Intro       from '../sections/page/home/Intro';

      // About Page
      import    Team        from '../sections/page/about/Team';

      // Subservice Page
      import    SubGrid     from '../sections/page/subservice/SubGrid';

      // Insights Page
      import    PostGrid    from '../sections/page/insights/PostGrid';

      // Post Page
      import    PostBody    from '../sections/page/post/PostBody';
      import    PostHero    from '../sections/page/post/PostHero';
      import    PostSidebar from '../sections/page/post/PostSidebar';


const Page = ({ match }) => {

  const [doc, setDocData] = useState(null);
  const [notFound, toggleNotFound] = useState(false);

  // Get the Prismic page UID
  const parent = match.params.parent || false;
  const uid = match.params.uid || 'index';
  var page = parseInt(match.params.page, 10);
  const posts_per_page = 6;

  // If anything other than the Insights page is paginated, throw
  // it back from whence it came.
  if( uid !== 'insights' && page > 0 ) {
    window.location.replace('/' + uid);
  }

  if( uid === 'insights' && page == 1 ) {
    window.location.replace('/insights');
  }


  // Use the UID to query Prismic for the page
  useEffect(() => {

    const fetchPage = async() => {

      const result = await client.getByUID('page', uid);
      const configuration = await client.getSingle('configuration');
      const navigation = await client.getSingle('nav');
      const recent_posts = await client.query(
        Prismic.Predicates.at('document.type', 'post'),
        {
          orderings: '[document.last_publication_date desc]',
          pageSize: 3,
          page: 1,
          fetchLinks: 'category.name'
        }
      );
      const use_cases = await client.query(
        Prismic.Predicates.at('document.type', 'use_case')
      );

      var pageData = {
        page: result,
        config: configuration,
        nav: navigation,
        recent_posts: recent_posts,
        use_cases: use_cases
      };

      if( uid === 'insights' ) {

        // If no page is set, set to 1.
        if( page > 1 ) {
          page = page;
        } else {
          page = 1;
        }

        // Add the posts data to the page data array.
        pageData.posts = await client.query(
          Prismic.Predicates.at('document.type', 'post'),
          {
            orderings: '[document.last_publication_date desc]',
            pageSize: posts_per_page,
            page: page,
            fetchLinks: 'category.name'
          }
        );
      }

      if ( configuration && result ) {
        // If the page exists, set the data appropriately.
        return setDocData( pageData );
      } else {
        // If the page does not exist, toggleNotFound.
        console.warn(
          'Page document not found. Make sure it exists in your Prismic repo.'
        );
        toggleNotFound(true);
      }

    }

    fetchPage();
  }, [uid]);

  if ( doc ) {              // If document data exists, render the page.

    if ( parent && !doc.page.data.is_sub ) {
      window.location.replace('/' + uid);
    } else if ( !parent && doc.page.data.is_sub ) {
      window.location.replace('/' + doc.page.data.parent.uid + '/' + uid);
    }

    // Cycle through the slices and produce the page content.
    const pageContent = doc.page.data.body.map(function (slice, index) {
      //console.log({slice});
      /*
      *   Page Hero Slice
      *   == The title bar that sits above each inner page.
      */
      if ( slice.slice_type === 'code' ) {
        return (
          <div dangerouslySetInnerHTML={{__html: slice.primary.html_code[0].text}} />
        );
      }
      /*
      *   Page Hero Slice
      *   == The title bar that sits above each inner page.
      */
      if ( slice.slice_type === 'page_hero' ) {
        return (
          <PageHero
            breadcrumb={ doc.page.data.breadcrumb }
            headline={ slice.primary.headline }
            caption={ slice.primary.caption }
            key={index}
          />
        );
      }

      /*
      *   Home Hero Slice
      *   == The large header above the homepage.
      */
      else if ( slice.slice_type === 'home_hero' ) {
        return (
            <HomeHero
              image={ slice.primary.image }
              mat={ slice.primary.mat }
              headline={ slice.primary.headline }
              caption={ slice.primary.caption }
              button_text={ slice.primary.button_text }
              button_link={ slice.primary.button_link }
              button_type={ slice.primary.button_type }
            />
        );
      }

      /*
      *   Split Section Slice
      *   == Image on one side, text on the other side.
      */
      else if ( slice.slice_type === 'split_section' ) {
        return (
            <Split
                hasAccent={ slice.primary.accent }
                content={ slice.primary.content }
                hasButton={ slice.primary.btn }
                button={{
                  'style':  slice.primary.btn_type,
                  'text':   slice.primary.btn_text,
                  'link':   slice.primary.btn_link
                }}
                image={ slice.primary.image }
                imageOn={ slice.primary.dir }
            />
        );
      }

      /*
      *   Image
      *   == A full-width image block in various styles.
      */
      else if ( slice.slice_type === 'image' ) {
        return (
          <Image
            theme={ slice.primary.theme }
            image={ slice.primary.image }
            accent={ slice.primary.accent }
          />
        );
      }

      /*
      *   Two Column
      *   == Two columns, WYSIWG on both sides.
      */
      else if ( slice.slice_type === 'two_column' ) {
        return (
          <TwoColumn
            one={ slice.primary.one }
            two={ slice.primary.two }
          />
        )
      }

      /*
      *   Team
      *   == A grid showing the members of the team.
      */
      else if ( slice.slice_type === 'team' ) {
        return (
          <Team
            heading={ slice.primary.heading }
            team={ slice.items }
          />
        )
      }

      /*
      *   Posts Grid
      *   == A grid of insights (blog) posts for the Insights page.
      */
      else if ( slice.slice_type === 'post_grid' ) {
        return (
          <PostGrid
            posts={ doc.posts.results }
            curr={ doc.posts.page }
            next={ doc.posts.next_page }
            prev={ doc.posts.prev_page }
          />
        )
      }

      /*
      *   CTA
      *   == Simple text + button call-to-action section.
      */
      else if ( slice.slice_type === 'cta' ) {
        return (
          <CallToAction
            text={ slice.primary.text }
            button_text={ slice.primary.button_text }
            button_link={ slice.primary.link }
          />
        )
      }

      /*
      *   Subservice Grid
      *   == A box grid of inner services for each service page.
      */
      else if ( slice.slice_type === 'subservice_grid' ) {
        return (
          <SubGrid
            subservices={ slice.items }
            background={ slice.primary.background }
            intro={ slice.primary.intro }
            button={{
              exists: slice.primary.button,
              text: slice.primary.button_text,
              link: slice.primary.button_link
            }}
          />
        )
      }

      /*
      *   Full Width Split
      *   == Similar to the split section, except the image extends.
      */
      else if ( slice.slice_type === 'full_width_split' ) {
        return (
          <FullWidthSplit
            dir={ slice.primary.dir.toLowerCase() }
            bg={ slice.primary.bg.toLowerCase().replace(" ", "-") }
            accent={ slice.primary.show_accent }
            image={ slice.primary.image }
            heading={ slice.primary.heading }
            content={ slice.primary.content }
            layout={ slice.primary.body_layout }
            button={ {
              exists: slice.primary.show_button,
              text: slice.primary.button_text,
              link: slice.primary.button_link
            } }
          />
        )
      }

      /*
      *   Latest Posts
      *   == Shows the three most recently published posts.
      */
      else if ( slice.slice_type === 'latest_posts' ) {
        return (
          <LatestPosts
            headline={ slice.primary.headline }
            posts={ doc.recent_posts.results }
          />
        )
      }

      /*
      *   Use Case Grid
      *   == Easy access links for various industry use cases.
      */
      else if ( slice.slice_type === 'use_case_grid' ) {
        return (
          <UseCaseGrid
            headline={ slice.primary.headline }
            cases={ doc.use_cases.results }
          />
        )
      }

      /*
      *   Clients
      *   == Easy access links for various industry use cases.
      */
      else if ( slice.slice_type === 'clients' ) {
        return (
          <Clients
            heading={ slice.primary.heading }
            caption={ slice.primary.caption }
            clients={ slice.items }
            hasButton={ slice.primary.show_button.toString() }
            button_text={ slice.primary.button_text }
            button_link={ slice.primary.button_link }
          />
        )
      }

      /*
      *   Intro
      *   == The introductory section specifically for the homepage
      */
      else if ( slice.slice_type === 'intro' ) {
        return (
          <Intro
            heading={ slice.primary.heading }
            caption={ slice.primary.caption }
            blocks={ slice.items }
            image={ slice.primary.image }
          />
        )
      }

      /*
      *   Platform Hero
      *   == A hero header section for the platform homepage.
      */
      else if ( slice.slice_type === 'platform_hero' ) {
        return (
          <PlatformHero
            heading={ slice.primary.heading }
            subheading={ slice.primary.subheading }
            color={ slice.primary.subheading_color }
          />
        );
      }

      else { return null; }


    });

    return (

      <div className="page" id="main-body">

        <Helmet
          titleTemplate="%s | Integrated Labor"
        >

          <title></title>
          <meta name="description" content="Meta description" />

        </Helmet>

        <div id="main-content">
          <Navbar
            links={ doc.nav.data.body }
            cta={{
              'show':   doc.nav.data.has_cta,
              'text':   doc.nav.data.cta_btn,
              'link':   doc.nav.data.cta_link
            }}
            logo={ doc.config.data.logo }
          />
          { pageContent }
          <Footer
            social={ doc.config.data.social }
            location={{
              'address1': doc.config.data.address_line_1,
              'address2': doc.config.data.address_line_2,
              'city':     doc.config.data.city,
              'state':    doc.config.data.state,
              'zip':      doc.config.data.zip
            }}
            contact={{
              'phone':    doc.config.data.phone,
              'email':    doc.config.data.email
            }}
            newsletter={ doc.config.data.newsletter }
            founded={ doc.config.data.founded }
          />
        </div>

      </div>

    );

  }

  return null;

};

export default Page;
