import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../../prismic-configuration';

const Team = ( { heading, team } ) => {

  const TeamMember = ( { photo, name, role } ) => {
    return(
      <div className="team-member">
        <div className="team-member__wrapper">
          <div className="team-member__photo"><img src={photo.url} alt={photo.alt} /></div>
          <div className="team-member__name">{ name }</div>
          <div className="team-member__role">{ role }</div>
        </div>
      </div>
    );
  }

  const TeamGrid = () => {

    return team.map( function( member, index ){
      return (
        <TeamMember
          photo={member.image}
          name={member.name}
          role={member.role}
          key={index} 
        />
      );
    });

  }

  return (
    <div className="section team">
      <div className="container">
        <div className="accent bg-green"></div>
        <h2 className="team__heading">{ heading }</h2>
        <div className="team__wrapper">
          <TeamGrid />
        </div>
      </div>
    </div>
  );
}

export default Team
