import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

const Image = ( { theme, image, accent } ) => {

  const Basic = () => {
    return (
      <div className="img-block img-block--basic">
        <div className="img-block__img">
          <img src={image.url} alt={image.alt} />
        </div>
      </div>
    );
  }

  const Theater = () => {
    return (
      <div className="img-block img-block--theater">
        <div className={`container bg-${ accent.toLowerCase().replace(' ', '-') }`}>
          <div className="img-block__img">
            <img src={image.url} alt={image.alt} />
          </div>
        </div>
      </div>
    );
  }

  if( theme === 'Theater' ) {
    return ( <Theater /> );
  } else if ( theme === 'Basic' ) {
    return ( <Basic /> );
  } else {
    return null;
  }

}

export default Image
