import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../../prismic-configuration';

import { Accent, Button } from '../../../components';

const SubGrid = ( { subservices, background, intro, button } ) => {

  var SubGridStyles = {};

  // If we have a background, add it to the styles
  if ( background ) { SubGridStyles.backgroundColor = background; }

  // If we have a button, display it.
  const Footer = ( { button } ) => {
    return (
      <div className="sub-grid__section-footer">
      <Button
          url={ Link.url(button.link, linkResolver) }
          type={`outline`}
          size='Medium'
          text={ button.text }
        />
      </div>
    );
  }
  // Added new function to create a slug for the subgrid id
  function titleToSlug(title) {
    return title.toLowerCase().replace(/[^a-z0-9-]/g, '-');
  }
  // If we have an intro, display it.
  const IntroBlock = ( { content } ) => {
    
    if ( content ) {
      //console.log( content[0].text );
      return (
        <div className="sub-grid__intro">
          <Accent color='green' />
          { RichText.render( content, linkResolver ) }
        </div>
      );
    }
    else {
      return null;
    }
  }

  // If we have an icon, display it.
  const Icon = ( { icon } ) => {
      return (
        <div className="sub-grid__icon">
          <img src={ icon.url } alt={ icon.alt } />
        </div>
      );
  }

  // If we have a jump link, display it.
  const JumpLink = ( { text, url } ) => {
    return (
      <footer className="sub-grid__footer">
        <a href={ url } title={ text }>{ text }<span className="arrow">&rsaquo;</span></a>
      </footer>
    );
  }

  // Generate the item.
  const SubService = ( { title, desc, icon, linkText, linkUrl } ) => {
    return (
      <div className="sub-grid__item">
        { icon ? <Icon icon={ icon } /> : '' }
        <h4 className="sub-grid__title">{title}</h4>
        <p className="sub-grid__desc">{desc}</p>
        { linkText ? <JumpLink text={ linkText } url={ linkUrl } /> : '' }
      </div>
    );
  }

  // Generate the grid.
  const SubServices = () => {
    return subservices.map( function( subservice, index ){
      return (
        <SubService
          title={subservice.name}
          desc={subservice.description}
          icon={ subservice.icon }
          linkText={ subservice.link_text }
          linkUrl={ subservice.link_url }
          key={index} 
        />
      );
    });
  }
  let slug = "subgrid"
  if (intro[0]) {
    slug = titleToSlug(intro[0].text);
  }
  console.log({button});
  // Return the element.
  return (
    <div className="section sub-grid" style={ SubGridStyles } id={slug}>
      <div className="container">
        { intro ? <IntroBlock content={ intro } /> : '' }
        <SubServices />
      </div>
      { button.text ? <Footer button={ button } /> : '' }
    </div>
  );
}

export default SubGrid
