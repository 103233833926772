import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

import { Accent, Button } from '../../components';

const FullWidthSplit = ( { dir, bg, accent, image, heading, content, layout, button } ) => {

  const ButtonElement = ({ buttonProps, bg }) => {
    if ( bg === 'dark-teal' ) {
      return <Button
          url={ Link.url(buttonProps.link, linkResolver) }
          type={`green`}
          size='Medium'
          text={ buttonProps.text }
        />;
    } else {
      return <Button
          url={ Link.url(buttonProps.link, linkResolver) }
          type={`outline`}
          size='Medium'
          text={ buttonProps.text }
        />;
    }
  }

  return (
    <div className={`section fw-split fw-split--${dir} bg-${bg}`}>
      <div className="fw-split__image"><img src={image.url} alt={image.alt} /></div>
      <div className={`container ${ layout === 'Stretch' ? 'stretch' : '' }`}>
        <div className="fw-split__body">
          <div className={`show-${accent-toString()}`}><Accent color='green' /></div>
          <h2 className="fw-split__heading">{ heading }</h2>
          { RichText.render( content, linkResolver ) }
          <div className="fw-split__footer">
            { button.exists ? <ButtonElement buttonProps={ button } bg={ bg } /> : '' }
          </div>
        </div>
      </div>
    </div>
  );

}

export default FullWidthSplit
