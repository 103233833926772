import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

const PlatformHero = ({ heading, subheading, color }) => {

  return (
    <div className="section platform-hero">
      <div className="container">
        <h1 className="platform-hero__heading">{heading}</h1>
        <p className={`platform-hero__subheading ${color.toLowerCase().replace(' ', '-')}`}>{subheading}</p>
      </div>
    </div>
  );
}

export default PlatformHero
