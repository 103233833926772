import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

import { Button } from '../../components';

const CallToAction = ( { text, button_text, button_link } ) => {
  return (
    <div className="section cta">
      <div className="container">
        <div className="cta__inner">
          <p className="cta__text">{ text }</p>
          <footer className="cta__footer">
          <Button
            url={ Link.url(button_link, linkResolver) }
            type={ `green` }
            size='Medium'
            text={ button_text } />
          </footer>
        </div>
      </div>
    </div>
  );
}

export default CallToAction
