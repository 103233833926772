import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

import { Button, Accent } from '../../components';

const Split = ( { hasAccent, content, hasButton, button, image, imageOn } ) => {

  // Show or hide the accent bar.
  const ShowAccent = ({ show }) => {
    if( show ) { return ( <Accent /> ); }
    else { return null; }
  }

  return (
    <div className={`section split split--${ imageOn.toLowerCase() }`}>
      <div className="container">
        <div className="split__column split__image">
          <img alt={ image.alt } src={ image.url } />
        </div>
        <div className="split__column split__content">
          <ShowAccent show={ hasAccent } />
          { RichText.render(content, linkResolver) }
          <footer className={`show-${hasButton}`}>
            <Button
              url={ Link.url(button.link, linkResolver) }
              type={ button.style.toLowerCase() }
              size='Medium'
              text={ button.text } />
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Split
