import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../../prismic-configuration';

import { Button } from '../../../components';

const Intro = ({ heading, caption, blocks, image }) => {

  const Block = ({ blockData }) => {
    return(
      <div className="intro-block">
        <div className="intro-block__icon"><img src={blockData.icon.url} alt={blockData.icon.alt} /></div>
        <h3 className="intro-block__heading">{ blockData.heading }</h3>
        <p className="intro-block__caption">{ blockData.caption }</p>
        <footer className="intro-block__footer">
        <Button
            url={ Link.url( blockData.link, linkResolver ) }
            type={`outline`}
            size='Medium'
            text={ blockData.button_text }
          />
        </footer>
      </div>
    );
  }

  const BlocksList = () => {
    if( blocks.length ) {
      return blocks.map(function( blockData, index ){
        return( <Block key={index} blockData={ blockData } /> );
      });
    } else {
      return null;
    }
  }

  return (
    <div className="section intro">
      <div className="intro__image">
        <img src={ image.url } alt={ image.alt } />
      </div>
      <div className="container">
        <div className="intro__body">
          <div className="accent bg-green"></div>
          <h2 className="intro__heading">{ heading }</h2>
          <p className={`intro__caption ${ caption !== null ? null : 'show-false' }`}>{ caption }</p>
          <div className="intro__blocks">
            <BlocksList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro
