import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

import { Button } from '../../components';

const HomeHero = ({ image, mat, headline, caption, button_text, button_link, button_type }) => {
  return (
    <div className="home-hero ">
      <div className={`home-hero__mat bg-${mat.toLowerCase().replace(' ', '-')}`}></div>
      <div className="home-hero__scroll">
        <div><img src="https://prismic-io.s3.amazonaws.com/integrated-labor/d0a8828a-e30d-4a4c-830e-29ad277ac480_arrow-ils4.svg" alt="Scroll Arrow" /> <span>Scroll</span></div>
      </div>
      <div className="home-hero__image">
        <img src={image.url} alt={image.alt} />
      </div>
      <div className="container">
        <div className="home-hero__body">
          <h1 className="home-hero__heading">{ headline }</h1>
          <p className={`home-hero__caption ${ caption === null ? 'show-false' : null }`}>{ caption }</p>
          <footer className="home-hero__footer">
            <Button
              url={ Link.url( button_link, linkResolver ) }
              type={ button_type.toLowerCase() }
              size='Large'
              text={ button_text }
            />
          </footer>
        </div>
      </div>
    </div>
  );
}

export default HomeHero
