import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

import { Button } from '../../components';

const LatestPosts = ({ headline, posts }) => {

  // Tags
  const LatestTags = ({ tags }) => {
    return tags.map( function( tag, index ) {
      return (
        <a key={index} href={`/insights?tag=${ tag.tag.toLowerCase().replace(' ', '-') }`} className="tag" title={tag.tag}>{tag.tag}</a>
      );
    });
  }

  // Output a post
  const LatestPost = ({ post }) => {
    return (
      <a className="recent-post" href={`/insights/${post.uid}`}>
        <div className="recent-post__image"><img src={post.data.image.thumb.url} alt={post.data.image.thumb.alt} /></div>
        <div className="recent-post__category">{ post.data.category.data.name }</div>
        <div className="recent-post__title">{ post.data.title }</div>
        <div className="recent-post__caption">{ post.data.caption }</div>
        <footer className="recent-post__tags">
          <LatestTags tags={ post.data.tags } />
        </footer>
      </a>
    );
  }

  // Output the posts
  const LatestPostsBlock = () => {
    if( posts.length ) {
      return posts.map(function( postData, index ){
        return (
          <LatestPost
            post={ postData }
            key={index} 
          />
        );
      });
    } else {
      return null;
    }
  }

  // Output the element
  return (
    <div className="section recent-posts">
      <div className="container">
        <header className="recent-posts__header">
          <h2 className="recent-posts__headline">{headline}</h2>
          <Button
              url={ `/insights` }
              type={`outline`}
              size='Medium'
              text='View All Insights'
            />
        </header>
        <div className={`recent-posts__posts ${ posts.length < 3 ? 'less-than-3' : null }`}>
          <LatestPostsBlock />
        </div>
      </div>
    </div>
  );
}

export default LatestPosts
