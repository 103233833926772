import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../../prismic-configuration';

const PostBody = ( { content } ) => {

  if( content ) {
    const PostSlices = content.map( function( slice, index ){

      if ( slice.slice_type === 'rich_text' ) {
        return RichText.render( slice.primary.content, linkResolver );
      }

    });

    return (
      <div className="post" >
        <div className="container">
          <main className="post__content">
            {PostSlices}
          </main>
          <aside className="post__sidebar">&nbsp;</aside>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default PostBody
