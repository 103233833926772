import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';
import { useLocation } from 'react-router-dom';

import { Button } from '../../components';

const UseCaseGrid = ({ headline, cases }) => {
  let caseLimit = 4;
  

  const location = useLocation();
  location.pathname === '/' ? caseLimit = 4 : caseLimit = 100;
  const Case = ({ caseData }) => {
    return (
      <div className="ucg-item">
        <div className="ucg-item__image">
          <img src={caseData.data.image.thumb.url} alt={caseData.data.image.thumb.alt} />
        </div>
        <div className="ucg-item__title">{ caseData.data.name }</div>
        <div className="ucg-item__caption">{ caseData.data.caption }</div>
        <footer className="ucg-item__footer">
          {/* <Button
            url={ `/platform/use-case/${caseData.uid}` }
            type={`outline`}
            size='Medium'
            text='Learn More'
          /> */}
        </footer>
      </div>
    );
  }

  const UseCases = () => {
    if (cases.length) {
      return cases.slice(0, caseLimit).map(function(caseData, index) {
        return (
          <Case caseData={caseData} key={index}  />
        );
      });
    } else {
      return null;
    }
  }

  return (
    <div className="section use-case-grid bg-light-grey">
      <div className="container">
        <header className="use-case-grid__header">
          <h2 className="use-case-grid__headline">{headline}</h2>
        </header>
        <div className="use-case-grid__grid">
          <UseCases />
        </div>
        { location.pathname === '/' ? (
          <footer className="use-case-grid__footer">
            <Button
              url={ `/use-cases` }
              type={`green`}
              size='Medium'
              text='Use Cases'
            />
          </footer>
        ) : null }
      </div>
    </div>
  );
}

export default UseCaseGrid
