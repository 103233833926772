import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../prismic-configuration';

const Button = ( { url, type, size, text } ) => {

  var classes = [];

  // Types
  if      ( type === 'outline' ) { classes.push( 'btn--ghost' ); }
  else if ( type === 'green' ) {
    classes.push( 'btn--solid' );
    classes.push( 'btn--green' );
  }

  //Sizes
  if      ( size === 'Medium' ) { classes.push( 'btn--std' ); }
  else if ( size === 'Large' ) { classes.push( 'btn--lg' ); }

  return (
    <a
      className={ `btn ${ classes.join(' ') }` }
      href={ url }
      title={ text }>
      { text }
    </a>
  );
}

export default Button
