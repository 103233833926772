import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import { client, linkResolver } from '../prismic-configuration';
import NotFound from './NotFound';
import { Helmet } from 'react-helmet';

// Components

  // Page Globals
  import    Navbar          from '../globals/Navbar';
  import    Footer          from '../globals/Footer';

  // SEO

  // Post
  import    PostBody    from '../sections/page/post/PostBody';
  import    PostHero    from '../sections/page/post/PostHero';
  import    PostSidebar from '../sections/page/post/PostSidebar';


const Post = ({ match }) => {

  const [doc, setDocData] = useState(null);
  const [notFound, toggleNotFound] = useState(false);

  const uid = match.params.uid;

  // Use the UID to query Prismic for the post
  useEffect(() => {

    const fetchPost = async() => {

      const result = await client.getByUID('post', uid,
      { 'fetchLinks': 'category.name' } );
      const configuration = await client.getSingle('configuration');
      const navigation = await client.getSingle('nav');

      var postData = {
        post: result,
        config: configuration,
        nav: navigation
      };

      if ( configuration && result ) {
        // If the page exists, set the data appropriately.
        return setDocData( postData );
      } else {
        // If the page does not exist, toggleNotFound.
        console.warn(
          'Post document not found. Make sure it exists in your Prismic repo.'
        );
        toggleNotFound(true);
      }

    }

    fetchPost();
  }, [uid]);


  if ( doc ) {              // If document data exists, render the page.

    return (

      <div className="page" id="main-body">

        <Helmet
          titleTemplate="%s | Integrated Labor"
        >

          <title>Meta Title</title>
          <meta name="description" content="Meta description" />

        </Helmet>

        <div id="main-content">
          <Navbar
            links={ doc.nav.data.body }
            cta={{
              'show':   doc.nav.data.has_cta,
              'text':   doc.nav.data.cta_btn,
              'link':   doc.nav.data.cta_link
            }}
            logo={ doc.config.data.logo }
          />
          <PostHero
            image={ doc.post.data.image }
            title={ doc.post.data.title }
            category={ doc.post.data.category.data.name }
          />
          <PostBody content={ doc.post.data.body } />
          <Footer
            social={ doc.config.data.social }
            location={{
              'address1': doc.config.data.address_line_1,
              'address2': doc.config.data.address_line_2,
              'city':     doc.config.data.city,
              'state':    doc.config.data.state,
              'zip':      doc.config.data.zip
            }}
            contact={{
              'phone':    doc.config.data.phone,
              'email':    doc.config.data.email
            }}
            newsletter={ doc.config.data.newsletter }
            founded={ doc.config.data.founded }
          />
        </div>

      </div>

    );
  }

  return null;
};

export default Post;
