import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../../prismic-configuration';

const PostHero = ({ image, title, category }) => {
  return (
    <div className="post-hero">
      <div className="post-hero__image"><img src={ image.url } alt={image.alt} /></div>
      <div className="container">
        <div className="breadcrumb">
          <span className="breadcrumb__link"><a href="/" title="Home">Home</a></span> /
          <span className="breadcrumb__link"><a href="/insights" title="Insights">Insights</a></span> / { category }
        </div>
        <h1 className="post-hero__title">{ title }</h1>
        <div className="post-hero__meta">
          <span className="post-hero__date">[Date]</span> | <span className="post-hero__author">[Author]</span>
        </div>
      </div>
    </div>
  );
}

export default PostHero
