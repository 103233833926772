import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

import { Button } from '../../components';

const Clients = ( { heading, caption, clients, hasButton, button_text, button_link } ) => {

  const Client = ({ clientData }) => {
    return (
      <div className="client"> <img src={ clientData.logo.url } alt={ clientData.client } /></div>
    );
  }

  const ClientGrid = () => {

    if ( clients.length ) {
      return clients.map(function( clientData, index ){
        return ( <Client clientData={clientData} key={index} /> );
      });
    } else {
      return null;
    }

  }

  return (
    <div className={`section split split--left clients`}>
      <div className="container">
        <div className="split__column split__image">
          <div className="clients__grid">
            <ClientGrid />
          </div>
        </div>
        <div className="split__column split__content">
          <h2>{heading}</h2>
          <p className={ caption === null ? 'show-false' : null }>{caption}</p>
          <footer className={`show-${hasButton}`}>
            <Button
              url={ Link.url(button_link, linkResolver) }
              type={ 'green' }
              size='Medium'
              text={ button_text } />
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Clients
